import './css'
// because webpack generated code does not go through babel
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

// Add JS Loaded class to the body
document.documentElement.classList.add('js-loaded');

// App main
const main = async () => {
  // Import Vendors
  await import('./_vendors.js');

  // Import Scripts
  await import('./_scripts.js');

  // Import Components
  await import('./_components.js');

  // Import Vue Components
  await import('./_vue-components.js');
};

// Execute async function
main().then(() => {});
